<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="900"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改支付模板' : '新建支付模板'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="支付模板名称:"
            name="name"
            :rules="{ required: true }"
          >
            <a-input
              v-model:value="form.name"
              placeholder="请输入支付模板名称"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="管理员备注:" name="remarks">
            <a-input
              v-model:value="form.remarks"
              placeholder="请输入管理员备注"
              allow-clear
            />
          </a-form-item>
          <a-form-item
            :rules="{ required: true }"
            style="margin-bottom: 0"
            label="排序:"
            name="sort"
          >
            <a-input-number
              :min="0"
              style="width: 100%"
              v-model:value="form.sort"
              placeholder="请输入排序"
              allow-clear
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
            <div>数字越小越靠前</div>
          </a-form-item>
          <a-form-item
            :rules="{ required: true }"
            style="margin-bottom: 0"
            label="支付方式:"
            name="method"
          >
            <a-radio-group v-model:value="form.method">
              <a-radio value="wechat" :disabled="isUpdate">微信支付</a-radio>
              <a-radio value="alipay" :disabled="isUpdate">支付宝</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
            <div>保存以后支付方式将不可修改,请谨慎操作</div>
            <div v-if="form.method === 'wechat'">
              微信支付商户平台:
              <a
                style="margin-left: 10px"
                target="_blank"
                href="https://pay.weixin.qq.com"
                >https://pay.weixin.qq.com</a
              >
            </div>
            <div v-else>
              支付宝开发者平台:
              <a
                style="margin-left: 10px"
                target="_blank"
                href="https://open.alipay.com/dev/workspace"
                >https://open.alipay.com/dev/workspace</a
              >
            </div>
          </a-form-item>
          <a-form-item
            v-if="form.method === 'wechat'"
            style="margin-bottom: 0"
            label="微信支付接口版本:"
            name="configObj.wechat.version"
          >
            <a-radio-group v-model:value="form.configObj.wechat.version">
              <a-radio value="v3">v3</a-radio>
              <a-radio value="v2">v2</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            v-if="form.method === 'wechat'"
            :wrapper-col="{ offset: 5, span: 19 }"
          >
            <div>v2版本较老已经不再支持新出的API接口,强烈建议使用v3</div>
          </a-form-item>
          <a-form-item
            v-if="form.method === 'wechat'"
            label="微信商户类型:"
            name="configObj.wechat.mchType"
          >
            <a-radio-group v-model:value="form.configObj.wechat.mchType">
              <a-radio value="normal">普通商户</a-radio>
              <a-radio value="provider">子商户(服务商模式)</a-radio>
            </a-radio-group>
          </a-form-item>

          <!-- 普通商户 -->
          <div
            v-if="
              form.configObj.wechat.mchType === 'normal' &&
              form.method === 'wechat'
            "
          >
            <a-form-item
              style="margin-bottom: 0"
              label="应用ID(AppID):"
              name="configObj.wechat.normal.appId"
            >
              <a-input
                v-model:value="form.configObj.wechat.normal.appId"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>
                微信小程序或者公众号的APPID,需要在哪个客户端支付就填写哪个,APP支付需要填写开放平台的应用APPID
              </div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="微信商户号(Mchid):"
              name="configObj.wechat.normal.mchId"
            >
              <a-input
                v-model:value="form.configObj.wechat.normal.mchId"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>微信支付的商户号,纯数字格式,例如:1600000109</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="支付密钥(APIKEY):"
              name="configObj.wechat.normal.apiKey"
            >
              <a-input
                v-model:value="form.configObj.wechat.normal.apiKey"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>"微信支付商户平台"-"账户中心"-"API安全"-"设置API密钥"</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="证书文件(CERT):"
              name="configObj.wechat.normal.apiclientCert"
            >
              <a-upload
                :before-upload="
                  (file) => beforeUpload(file, 'apiclientCert', 'pem')
                "
                :customRequest="({ file }) => uploadFile(file, 'apiclientCert')"
                @remove="removeFile"
                :maxCount="1"
                :fileList="apiclientCertList"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  选择文件
                </a-button>
              </a-upload>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>请上传"apiclient_cert.pem"文件</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="证书文件(KEY):"
              name="configObj.wechat.normal.apiclientKey"
            >
              <a-upload
                :before-upload="
                  (file) => beforeUpload(file, 'apiclientKey', 'pem')
                "
                :fileList="apiclientKeyList"
                :maxCount="1"
                :customRequest="({ file }) => uploadFile(file, 'apiclientKey')"
                @remove="removeFile"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  选择文件
                </a-button>
              </a-upload>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>请上传"apiclient_key.pem"文件</div>
            </a-form-item>
          </div>

          <!-- 子商户 -->
          <div
            v-if="
              form.configObj.wechat.mchType === 'provider' &&
              form.method === 'wechat'
            "
          >
            <a-form-item
              style="margin-bottom: 0"
              label="服务商应用ID(AppID):"
              name="configObj.wechat.provider.spAppId"
            >
              <a-input
                v-model:value="form.configObj.wechat.provider.spAppId"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>请填写微信支付服务商的APPID</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="服务商户号(MchId):"
              name="configObj.wechat.provider.spMchId"
            >
              <a-input
                v-model:value="form.configObj.wechat.provider.spMchId"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>微信支付服务商的商户号,纯数字格式,例如:1600000109</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="服务商密钥(APIKEY):"
              name="configObj.wechat.provider.spApiKey"
            >
              <a-input
                v-model:value="form.configObj.wechat.provider.spApiKey"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>"微信支付商户平台"-"账户中心"-"API安全"-"设置API密钥"</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="子商户应用ID(APPID):"
              name="configObj.wechat.provider.subAppId"
            >
              <a-input
                v-model:value="form.configObj.wechat.provider.subAppId"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>
                微信小程序或者公众号的APPID,需要在哪个客户端支付就填写哪个,APP支付需要填写开放平台的应用APPID
              </div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="子商户号(MchId):"
              name="configObj.wechat.provider.subMchId"
            >
              <a-input
                v-model:value="form.configObj.wechat.provider.subMchId"
              ></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>微信支付服务商的商户号,纯数字格式,例如:1600000109</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="服务商证书文件(CERT):"
              name="configObj.wechat.provider.spApiclientCert"
            >
              <a-upload
                :customRequest="
                  ({ file }) => uploadFile(file, 'spApiclientCert')
                "
                :before-upload="
                  (file) => beforeUpload(file, 'spApiclientCert', 'pem')
                "
                @remove="removeFile"
                :maxCount="1"
                :fileList="spApiclientCertList"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  选择文件
                </a-button>
              </a-upload>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>请上传"apiclient_cert.pem"文件</div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="服务商证书文件(KEY):"
              :before-upload="beforeUpload"
              name="configObj.wechat.provider.spApiclientKey"
            >
              <a-upload
                :fileList="spApiclientKeyList"
                :maxCount="1"
                :before-upload="
                  (file) => beforeUpload(file, 'spApiclientKey', 'pem')
                "
                :customRequest="
                  ({ file }) => uploadFile(file, 'spApiclientKey')
                "
                @remove="removeFile"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  选择文件
                </a-button>
              </a-upload>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>请上传"apiclient_key.pem"文件</div>
            </a-form-item>
          </div>

          <div v-if="form.method === 'alipay'">
            <a-form-item
              style="margin-bottom: 0"
              label="支付宝应用(AppID):"
              name="configObj.alipay.appId"
            >
              <a-input v-model:value="form.configObj.alipay.appId"></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>支付宝分配给开发者的应用ID;例如202172300007148</div>
            </a-form-item>
            <a-form-item
              label="签名算法(signType):"
              name="configObj.alipay.signType"
            >
              <a-radio-group v-model:value="form.configObj.alipay.signType">
                <a-radio value="RSA2">RSA2</a-radio>
                <a-radio value="RSA" :disabled="true">RSA</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="加签模式(signMode):"
              name="configObj.alipay.signMode"
            >
              <a-radio-group v-model:value="form.configObj.alipay.signMode">
                <a-radio :value="10">公钥证书</a-radio>
                <a-radio :value="20">公钥</a-radio>
              </a-radio-group>
            </a-form-item>
            <div v-if="form.configObj.alipay.signMode === 10">
              <a-form-item
                style="margin-bottom: 0"
                label="应用公钥证书:"
                name="configObj.alipay.appCertPublicKey"
              >
                <a-upload
                  :customRequest="
                    ({ file }) => uploadFile(file, 'appCertPublicKey')
                  "
                  :before-upload="
                    (file) => beforeUpload(file, 'appCertPublicKey', 'crt')
                  "
                  @remove="removeFile"
                  :maxCount="1"
                  :fileList="appCertPublicKeyList"
                >
                  <a-button>
                    <upload-outlined></upload-outlined>
                    选择文件
                  </a-button>
                </a-upload>
              </a-form-item>
              <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
                <div>请上传"appCertPublicKey.crt"文件</div>
              </a-form-item>
              <a-form-item
                style="margin-bottom: 0"
                label="支付宝公钥证书:"
                name="configObj.alipay.alipayCertPublicKey"
              >
                <a-upload
                  :before-upload="
                    (file) => beforeUpload(file, 'alipayCertPublicKey', 'crt')
                  "
                  :customRequest="
                    ({ file }) => uploadFile(file, 'alipayCertPublicKey')
                  "
                  @remove="removeFile"
                  :maxCount="1"
                  :fileList="alipayCertPublicKeyList"
                >
                  <a-button>
                    <upload-outlined></upload-outlined>
                    选择文件
                  </a-button>
                </a-upload>
              </a-form-item>
              <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
                <div>请上传"alipayCertPublicKey_RSA2.crt"文件</div>
              </a-form-item>
              <a-form-item
                style="margin-bottom: 0"
                label="支付宝根证书:"
                name="configObj.alipay.alipayRootCert"
              >
                <a-upload
                  :before-upload="
                    (file) => beforeUpload(file, 'alipayRootCert', 'crt')
                  "
                  :customRequest="
                    ({ file }) => uploadFile(file, 'alipayRootCert')
                  "
                  @remove="removeFile"
                  :maxCount="1"
                  :fileList="alipayRootCertList"
                >
                  <a-button>
                    <upload-outlined></upload-outlined>
                    选择文件
                  </a-button>
                </a-upload>
              </a-form-item>
              <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
                <div>请上传"alipayRootCert.crt"文件</div>
              </a-form-item>
            </div>
            <a-form-item
              v-if="form.configObj.alipay.signMode === 20"
              style="margin-bottom: 0"
              label="支付宝公钥(alipayPublicKey):"
              name="configObj.alipay.alipayPublicKey"
            >
              <a-textarea
                :rows="4"
                v-model:value="form.configObj.alipay.alipayPublicKey"
              ></a-textarea>
            </a-form-item>
            <a-form-item
              v-if="form.configObj.alipay.signMode === 20"
              :wrapper-col="{ offset: 5, span: 19 }"
            >
              <div>
                可在"支付宝开发平台"-"应用信息"-"接口加签方式"-"支付宝公钥"中复制
              </div>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0"
              label="应用私钥(privateKey):"
              name="configObj.alipay.merchantPrivateKey"
            >
              <a-textarea
                :rows="4"
                v-model:value="form.configObj.alipay.merchantPrivateKey"
              ></a-textarea>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
              <div>查看"应用私钥_RSA2_PKCS8.txt"文件,将全部内容复制到此处</div>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as payMentTemplateApi from '@/api/payment/template.js'
import setting from '@/config/setting.js'
import { UploadOutlined } from '@ant-design/icons-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'templateEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    UploadOutlined
  },
  data() {
    return {
      uploadUrl: setting.uploadUrl,
      apiclientCertList: [],
      apiclientKeyList: [],
      spApiclientCertList: [],
      spApiclientKeyList: [],
      alipayCertPublicKeyList: [],
      appCertPublicKeyList: [],
      alipayRootCertList: [],
      form: Object.assign({}, this.data),
      logoPicList: [],
      // 编辑弹窗表单验证规则
      rules: {
        'configObj.wechat.normal.mchId': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value) => {
            if (this.form.configObj.wechat.normal.mchId) {
              const reg = /^[0-9]*$/
              if (reg.test(this.form.configObj.wechat.normal.mchId)) {
                return Promise.resolve()
              } else {
                return Promise.reject(new Error('微信商户号只能为纯数字格式'))
              }
            } else {
              return Promise.reject(new Error('请输入微信商户号(MchId)'))
            }
          }
        },
        'configObj.wechat.version': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.version) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请选择微信支付接口版本'))
            }
          }
        },
        'configObj.wechat.mchType': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.mchType) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请选择微信商户号类型'))
            }
          }
        },
        'configObj.wechat.normal.appId': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.normal.appId) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请填写应用ID(APPID)'))
            }
          }
        },
        'configObj.wechat.normal.apiKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.normal.apiKey) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请填写支付密钥(APIKEY)'))
            }
          }
        },
        'configObj.wechat.normal.apiclientCert': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.normal.apiclientCert) {
              const fileType =
                this.form.configObj.wechat.normal.apiclientCert.replace(
                  /.+\./,
                  ''
                )
              if (fileType !== 'pem') {
                return Promise.reject(new Error('请上传支持的.pem文件格式!'))
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.reject(new Error('请上传证书文件(CERT)'))
            }
          }
        },
        'configObj.wechat.normal.apiclientKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.normal.apiclientKey) {
              const fileType =
                this.form.configObj.wechat.normal.apiclientKey.replace(
                  /.+\./,
                  ''
                )
              if (fileType !== 'pem') {
                return Promise.reject(new Error('请上传支持的.pem文件格式!'))
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.reject(new Error('请上传证书文件(KEY)'))
            }
          }
        },
        'configObj.wechat.provider.spAppId': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.provider.spAppId) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请填写服务商应用ID(APPID)'))
            }
          }
        },
        'configObj.wechat.provider.spMchId': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.provider.spMchId) {
              const reg = /^[0-9]*$/
              if (reg.test(this.form.configObj.wechat.provider.spMchId)) {
                return Promise.resolve()
              } else {
                return Promise.reject(new Error('服务商户号只能为纯数字格式'))
              }
            } else {
              return Promise.reject(new Error('请输入服务商户号(MchId)'))
            }
          }
        },
        'configObj.wechat.provider.spApiKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.provider.spMchId) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请填写服务商密钥(APIKEY)'))
            }
          }
        },
        'configObj.wechat.provider.subMchId': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.provider.subMchId) {
              const reg = /^[0-9]*$/
              if (reg.test(this.form.configObj.wechat.provider.subMchId)) {
                return Promise.resolve()
              } else {
                return Promise.reject(new Error('子商户号只能为纯数字格式'))
              }
            } else {
              return Promise.reject(new Error('请输入子商户号(MchId)'))
            }
          }
        },
        'configObj.wechat.provider.subAppId': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.provider.subAppId) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请填写子商户应用ID(APPID)'))
            }
          }
        },
        'configObj.wechat.provider.spApiclientCert': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.provider.spApiclientCert) {
              const fileType =
                this.form.configObj.wechat.provider.spApiclientCert.replace(
                  /.+\./,
                  ''
                )
              if (fileType !== 'pem') {
                return Promise.reject(new Error('请上传支持的.pem文件格式!'))
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.reject(new Error('请上传服务商证书文件(CERT)'))
            }
          }
        },
        'configObj.wechat.provider.spApiclientKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.wechat.provider.spApiclientKey) {
              const fileType =
                this.form.configObj.wechat.provider.spApiclientKey.replace(
                  /.+\./,
                  ''
                )
              if (fileType !== 'pem') {
                return Promise.reject(new Error('请上传支持的.pem文件格式!'))
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.reject(new Error('请上传服务商证书文件(KEY)'))
            }
          }
        },
        'configObj.alipay.appId': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.appId) {
              const reg = /^[0-9]*$/
              if (reg.test(this.form.configObj.alipay.appId)) {
                return Promise.resolve()
              } else {
                return Promise.reject(new Error('支付宝应用ID只能为纯数字格式'))
              }
            } else {
              return Promise.reject(new Error('请输入支付宝应用ID(APPID)'))
            }
          }
        },
        'configObj.alipay.signType': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.signType) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请选择签名算法(signType)'))
            }
          }
        },
        'configObj.alipay.signMode': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.signMode) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请选择加签模式'))
            }
          }
        },
        'configObj.alipay.alipayPublicKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.alipayPublicKey) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请复制支付宝公钥'))
            }
          }
        },
        'configObj.alipay.merchantPrivateKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.merchantPrivateKey) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('请复制应用私钥'))
            }
          }
        },
        'configObj.alipay.appCertPublicKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.appCertPublicKey) {
              const fileType =
                this.form.configObj.alipay.appCertPublicKey.replace(/.+\./, '')
              if (fileType !== 'crt') {
                return Promise.reject(new Error('请上传支持的.crt文件格式!'))
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.reject(new Error('请上传支付宝应用公钥证书'))
            }
          }
        },
        'configObj.alipay.alipayCertPublicKey': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.alipayCertPublicKey) {
              const fileType =
                this.form.configObj.alipay.alipayCertPublicKey.replace(
                  /.+\./,
                  ''
                )
              if (fileType !== 'crt') {
                return Promise.reject(new Error('请上传支持的.crt文件格式!'))
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.reject(new Error('请上传支付宝公钥证书'))
            }
          }
        },
        'configObj.alipay.alipayRootCert': {
          required: true,
          trigger: ['change', 'blur'],
          validator: (rules, value, callback) => {
            if (this.form.configObj.alipay.alipayRootCert) {
              const fileType =
                this.form.configObj.alipay.alipayRootCert.replace(/.+\./, '')
              if (fileType !== 'crt') {
                return Promise.reject(new Error('请上传支持的.crt文件格式!'))
              } else {
                return Promise.resolve()
              }
            } else {
              return Promise.reject(new Error('请上传支付宝根证书'))
            }
          }
        }
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.isUpdate = true
        this.initForm(this.isUpdate)
      } else {
        this.isUpdate = false
        this.initForm(this.isUpdate)
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    // 初始化表单
    initForm(isUpdate) {
      const configObj = {
        wechat: {
          mchType: 'normal',
          version: 'v3',
          normal: {
            appId: '',
            mchId: '',
            apiKey: '',
            apiclientCert: '',
            apiclientKey: ''
            // platformCert: '',
            // platformCertSerial: ''
          },
          provider: {
            spAppId: '',
            spMchId: '',
            spApiKey: '',
            subAppId: '',
            subMchId: '',
            spApiclientCert: '',
            spApiclientKey: ''
          }
        },
        alipay: {
          appId: '',
          signType: 'RSA2',
          signMode: 10,
          alipayPublicKey: '',
          appCertPublicKey: '',
          alipayCertPublicKey: '',
          alipayRootCert: '',
          merchantPrivateKey: ''
        }
      }
      if (isUpdate) {
        let configObj = null
        if (this.data.config) {
          configObj = JSON.parse(this.data.config)
        }
        this.form = Object.assign(
          { sort: 100, method: 'wechat', configObj },
          this.data
        )
        if (this.form.configObj.wechat.normal.apiclientCert) {
          const name = this.subStr(
            this.form.configObj.wechat.normal.apiclientCert
          )
          this.apiclientCertList = [
            {
              uid: '-4',
              name,
              status: 'done',
              url: this.form.configObj.wechat.normal.apiclientCert
            }
          ]
        }
        if (this.form.configObj.wechat.normal.apiclientKey) {
          const name = this.subStr(
            this.form.configObj.wechat.normal.apiclientKey
          )
          this.apiclientKeyList = [
            {
              uid: '-5',
              name,
              status: 'done',
              url: this.form.configObj.wechat.normal.apiclientKey
            }
          ]
        }
        if (this.form.configObj.wechat.provider.spApiclientCert) {
          const name = this.subStr(
            this.form.configObj.wechat.provider.spApiclientCert
          )
          this.spApiclientCertList = [
            {
              uid: '-6',
              name,
              status: 'done',
              url: this.form.configObj.wechat.provider.spApiclientCert
            }
          ]
        }
        if (this.form.configObj.wechat.provider.spApiclientKey) {
          const name = this.subStr(
            this.form.configObj.wechat.provider.spApiclientKey
          )
          this.spApiclientKeyList = [
            {
              uid: '-7',
              name,
              status: 'done',
              url: this.form.configObj.wechat.provider.spApiclientKey
            }
          ]
        }
        if (this.form.configObj.alipay.appCertPublicKey) {
          const name = this.subStr(this.form.configObj.alipay.appCertPublicKey)
          this.appCertPublicKeyList = [
            {
              uid: '-8',
              name,
              status: 'done',
              url: this.form.configObj.alipay.appCertPublicKey
            }
          ]
        }
        if (this.form.configObj.alipay.alipayCertPublicKey) {
          const name = this.subStr(
            this.form.configObj.alipay.alipayCertPublicKey
          )
          this.alipayCertPublicKeyList = [
            {
              uid: '-9',
              name,
              status: 'done',
              url: this.form.configObj.alipay.alipayCertPublicKey
            }
          ]
        }
        if (this.form.configObj.alipay.alipayRootCert) {
          const name = this.subStr(this.form.configObj.alipay.alipayRootCert)
          this.alipayRootCertList = [
            {
              uid: '-10',
              name,
              status: 'done',
              url: this.form.configObj.alipay.alipayRootCert
            }
          ]
        }
      } else {
        this.form = {
          sort: 100,
          method: 'wechat',
          configObj
        }
        this.apiclientCertList = []
        this.apiclientKeyList = []
        this.spApiclientCertList = []
        this.spApiclientKeyList = []
        this.appCertPublicKeyList = []
        this.alipayCertPublicKeyList = []
        this.alipayRootCertList = []
      }
    },
    beforeUpload(file, name, type) {
      console.log(file, name, type)
      const { name: fileName } = file
      const fileType = fileName.replace(/.+\./, '')
      if (fileType !== type) {
        this[`${name}List`] = []

        if (name === 'apiclientCert') {
          this.form.configObj.wechat.normal.apiclientCert = ''
          this.$nextTick(() => {
            this.$refs.form.validateFields([
              'configObj.wechat.normal.apiclientCert'
            ])
          })
        }
        if (name === 'apiclientKey') {
          this.form.configObj.wechat.normal.apiclientKey = ''
          this.$nextTick(() => {
            this.$refs.form.validateFields([
              'configObj.wechat.normal.apiclientKey'
            ])
          })
        }
        if (name === 'spApiclientCert') {
          this.form.configObj.wechat.provider.spApiclientCert = ''
          this.$nextTick(() => {
            this.$refs.form.validateFields([
              'configObj.wechat.provider.spApiclientCert'
            ])
          })
        }
        if (name === 'spApiclientKey') {
          this.form.configObj.wechat.provider.spApiclientKey = ''
          this.$nextTick(() => {
            this.$refs.form.validateFields([
              'configObj.wechat.provider.spApiclientKey'
            ])
          })
        }
        if (name === 'appCertPublicKey') {
          this.form.configObj.alipay.appCertPublicKey = ''
          this.$nextTick(() => {
            this.$refs.form.validateFields([
              'configObj.alipay.appCertPublicKey'
            ])
          })
        }
        if (name === 'alipayCertPublicKey') {
          this.form.configObj.alipay.alipayCertPublicKey = ''
          this.$nextTick(() => {
            this.$refs.form.validateFields([
              'configObj.alipay.alipayCertPublicKey'
            ])
          })
        }
        if (name === 'alipayRootCert') {
          this.form.configObj.alipay.alipayRootCert = ''
          this.$nextTick(() => {
            this.$refs.form.validateFields(['configObj.alipay.alipayRootCert'])
          })
        }
        this.$message.error('请上传支持的.' + type + '文件格式!')
        return false
      }
      return true
    },
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (file.uid === '-4') {
              this.form.configObj.wechat.normal.apiclientCert = ''
            }
            if (file.uid === '-5') {
              this.form.configObj.wechat.normal.apiclientKey = ''
            }
            if (file.uid === '-6') {
              this.form.configObj.wechat.provider.spApiclientCert = ''
            }
            if (file.uid === '-7') {
              this.form.configObj.wechat.provider.spApiclientKey = ''
            }
            if (file.uid === '-8') {
              this.form.configObj.alipay.appCertPublicKey = ''
            }
            if (file.uid === '-9') {
              this.form.configObj.alipay.alipayCertPublicKey = ''
            }
            if (file.uid === '-10') {
              this.form.configObj.alipay.alipayRootCert = ''
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            const Name = this.subStr(res.data.location)
            if (name === 'apiclientCert') {
              this.apiclientCertList[0] = {
                uid: '-4',
                name: Name,
                status: 'done',
                url: res.data.location
              }
              this.form.configObj.wechat.normal.apiclientCert =
                res.data.location
              this.$nextTick(() => {
                this.$refs.form.validateFields([
                  'configObj.wechat.normal.apiclientCert'
                ])
              })
            }
            if (name === 'apiclientKey') {
              this.apiclientKeyList[0] = {
                uid: '-5',
                name: Name,
                status: 'done',
                url: res.data.location
              }
              this.form.configObj.wechat.normal.apiclientKey = res.data.location
              this.$nextTick(() => {
                this.$refs.form.validateFields([
                  'configObj.wechat.normal.apiclientKey'
                ])
              })
            }
            if (name === 'spApiclientCert') {
              this.spApiclientCertList[0] = {
                uid: '-6',
                name: Name,
                status: 'done',
                url: res.data.location
              }
              this.form.configObj.wechat.provider.spApiclientCert =
                res.data.location
              this.$nextTick(() => {
                this.$refs.form.validateFields([
                  'configObj.wechat.provider.spApiclientCert'
                ])
              })
            }
            if (name === 'spApiclientKey') {
              this.spApiclientKeyList[0] = {
                uid: '-7',
                name: Name,
                status: 'done',
                url: res.data.location
              }
              this.form.configObj.wechat.provider.spApiclientKey =
                res.data.location
              this.$nextTick(() => {
                this.$refs.form.validateFields([
                  'configObj.wechat.provider.spApiclientKey'
                ])
              })
            }
            if (name === 'appCertPublicKey') {
              this.appCertPublicKeyList[0] = {
                uid: '-8',
                name: Name,
                status: 'done',
                url: res.data.location
              }
              this.form.configObj.alipay.appCertPublicKey = res.data.location
              this.$nextTick(() => {
                this.$refs.form.validateFields([
                  'configObj.alipay.appCertPublicKey'
                ])
              })
            }
            if (name === 'alipayCertPublicKey') {
              this.alipayCertPublicKeyList[0] = {
                uid: '-9',
                name: Name,
                status: 'done',
                url: res.data.location
              }
              this.form.configObj.alipay.alipayCertPublicKey = res.data.location
              this.$nextTick(() => {
                this.$refs.form.validateFields([
                  'configObj.alipay.alipayCertPublicKey'
                ])
              })
            }
            if (name === 'alipayRootCert') {
              this.alipayRootCertList[0] = {
                uid: '-10',
                name: Name,
                status: 'done',
                url: res.data.location
              }
              this.form.configObj.alipay.alipayRootCert = res.data.location
              this.$nextTick(() => {
                this.$refs.form.validateFields([
                  'configObj.alipay.alipayRootCert'
                ])
              })
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    /* 保存编辑 */
    save() {
      if (!this.isUpdate) {
        this.form.corpId = this.getCorpId()
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          const config = JSON.stringify(this.form.configObj)
          const { name, sort, method, remarks, templateId, corpId } = this.form
          let data = null
          if (templateId) {
            data = { config, name, sort, method, remarks, templateId,corpId }
          } else {
            data = { config, name, sort, method, remarks, corpId }
          }
          payMentTemplateApi
            .save(data, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {
          console.log('校验失败')
        })
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    subStr(str) {
      const index = str.lastIndexOf('/')
      return str.substring(index + 1, str.length)
    }
  }
}
</script>
